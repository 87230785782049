import React from "react"
import { useTranslation } from "react-i18next"
import AccountInsightsDetailsToggleWrapper from "./AccountInsightDetails/AccountInsightDetailsToggleWrapper"
import AccountAudienceDetailsToggleWrapper from "./AccountAudienceDetails/AccountAudienceDetailsToggleWrapper"
import AccountContentWrapper from "./AccountContentWrapper"
import {
  APPENDIX_INSIGHTS_DISPLAY_TOGGLES,
  APPENDIX_AUDIENCE_DISPLAY_TOGGLES,
  APPENDIX_CONTENT_DISPLAY_TOGGLES,
} from "../../../util/constant"
import "./style.sass"
import Divider from "../../Divider"

type Props = {
  toggles: string[]
}

export default function AccountAppendixWrapper({ toggles }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.AccountAppendix" })

  const checkDisplayByToggles = (
    toggleSet: Record<string, string>,
  ): boolean => {
    const ALL_TOGGLE_VALUES = new Set(Object.values(toggleSet))
    return toggles.some((toggle) => ALL_TOGGLE_VALUES.has(toggle))
  }

  return (
    <div className="cp_account-appendix-wrapper">
      { checkDisplayByToggles(APPENDIX_INSIGHTS_DISPLAY_TOGGLES) && (
        <>
          <h2>{ translate("Account Insights") }</h2>
          <Divider />
          <AccountInsightsDetailsToggleWrapper isAppendixTab={ true } />
        </>
      ) }
      { checkDisplayByToggles(APPENDIX_AUDIENCE_DISPLAY_TOGGLES) && (
      <>
        <h2>{ translate("Audience") }</h2>
        <Divider />
        <AccountAudienceDetailsToggleWrapper isAppendixTab={ true } />
      </>
      ) }
      { checkDisplayByToggles(APPENDIX_CONTENT_DISPLAY_TOGGLES) && (
      <>
        <h2>{ translate("Content") }</h2>
        <Divider className="last-divider" />
        <AccountContentWrapper isAppendixTab={ true } />
      </>
      ) }
    </div>
  )
}

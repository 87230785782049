import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import CancelIcon from "@mui/icons-material/Cancel"

import RelevantPostsByImageTag from "../Content/RelevantPostsByImageTag"
import RelevantPostsByKeywords from "../Content/RelevantPostsByKeywords"
import Search from "../Content/Search"
import Switch from "../../Switch"
import Tabs, { TabLabelProps } from "../../Tabs"
import TopPostsSubTab from "../Content/TopPostsSubTab"
import { CONTENT_DISPLAY_TOGGLES, APPENDIX_CONTENT_DISPLAY_TOGGLES } from "../../../util/constant"
import { SuggestionListToggleGroupType, ToggleFragment } from "../../../graphql"

import "./list-suggestions-social.sass"
import { SuggestionListContentTabPathsEnum } from "../../../util/types"

interface SuggestionsTabLabelProps extends TabLabelProps {
  path: SuggestionListContentTabPathsEnum.TOP_POSTS
  | SuggestionListContentTabPathsEnum.KEYWORDS
  | SuggestionListContentTabPathsEnum.IMAGE_TAGS
}

type Props = {
  toggles: ToggleFragment[]
  isAppendixTab?: boolean
}

export default function ListSuggestionSocialContent({ toggles, isAppendixTab }: Props) {
  const { t: translate } = useTranslation([], { keyPrefix: "component.ListSuggestionSocialContent" })
  const navigate = useNavigate()
  const {
    vanity,
    contentTabPath,
    listID,
    socialProfileID,
    tabPath,
  } = useParams()
  const [ topPostsOverviewOn, setTopPostsOverviewOn ] = React.useState(false)
  const instanceConstantToggles = isAppendixTab ? APPENDIX_CONTENT_DISPLAY_TOGGLES : CONTENT_DISPLAY_TOGGLES

  const tabOrder = React.useMemo((): ToggleFragment[] => {
    if (isAppendixTab) {
      const codes = Object.values(instanceConstantToggles)
      return toggles.filter((t) => codes.includes(t.name))
    }

    return toggles
      .filter(({ type }) => type === SuggestionListToggleGroupType.ContentToggles)
      .sort((a, b) => a.order - b.order)
  }, [ toggles ])

  const tabs: SuggestionsTabLabelProps[] = React.useMemo(() => {
    const t = tabOrder
      .filter((toggle) => toggle.name !== instanceConstantToggles.POST_IMPRESSIONS)
      .map((toggle) => {
        switch (toggle.name) {
          case instanceConstantToggles.TOP_POSTS:
            return { label: translate("Top Posts"), path: SuggestionListContentTabPathsEnum.TOP_POSTS }
          default:
            return [ {
              label: translate("Relevant Keywords"), path: SuggestionListContentTabPathsEnum.KEYWORDS,
            }, {
              label: translate("Relevant Image Tags"), path: SuggestionListContentTabPathsEnum.IMAGE_TAGS,
            } ]
        }
      }).flat()
    return t
  }, [ translate ])

  const setSubTab = (tabIndex: number) => {
    if (tabs.length > 0) {
      const newTabPath = tabs[tabIndex].path
      navigate(`/${ vanity }/lists/${ listID }/social-account/${ socialProfileID }/${ tabPath }/${ newTabPath }`)
    }
  }

  useEffect(() => {
    setSubTab(0)
  }, [ tabs ])

  const tabContent = () => {
    switch (contentTabPath) {
      case SuggestionListContentTabPathsEnum.KEYWORDS:
        return (
          <div className="cp_list_suggestions-social-content-key-words">
            <RelevantPostsByKeywords topPostsOverviewOn={ topPostsOverviewOn } />
          </div>

        )
      case SuggestionListContentTabPathsEnum.IMAGE_TAGS:
        return (
          <div className="cp_list_suggestions-social-content-image-tags">
            <RelevantPostsByImageTag topPostsOverviewOn={ topPostsOverviewOn } />
          </div>
        )
      case SuggestionListContentTabPathsEnum.TOP_POSTS:
        return <TopPostsSubTab topPostsOverviewOn={ topPostsOverviewOn } />
      case undefined:
      default:
        return (
          <div className="cp_list_suggestions-social-content-top-posts">
            <TopPostsSubTab topPostsOverviewOn={ topPostsOverviewOn } />
          </div>
        )
    }
  }

  const tabIndex = React.useMemo(() => {
    const foundTab = tabs.find(({ path }) => path === contentTabPath)
    return foundTab ? tabs.indexOf(foundTab) : 0
  }, [ contentTabPath ])

  return (
    <>
      <div className="cp_profile_component-list-tabs-container sub-tabs">
        <Tabs
          handleChange={ setSubTab }
          tabs={ tabs }
          defaultValue={ tabIndex }
          controlledValue={ tabIndex }
          controls={ (
            <div className="cp_profile_component-list-tabs-actions">
              <p>{ translate("Preview") }</p>
              <Switch
                handleChange={ () => setTopPostsOverviewOn(!topPostsOverviewOn) }
                isChecked={ topPostsOverviewOn }
                hasIcon={ true }
                icon={ <CancelIcon /> }
                checkedIcon={ <CancelIcon /> }
              />
            </div>
          ) }
        />
        <div className="cp_profile_component-list-tabs-content">
          { tabContent() }
        </div>
      </div>
      <Search
        mountContext="listSocialAccount"
        contentContextMenu={ {
          showAddPostButton: true,
          showViewMatchingPostsButton: false,
          showViewProfileButton: false,
        } }
      />
    </>
  )
}
